define('m03/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    version: "##version##",

    wuerth: true, //change only this one!
    swg: false, //change only this one!
    itw: false, //change only this one!
    pollmeier: false, //change only this one!
    verbaende: false,

    environment: "m03", // "offline" = offline Version, "m03" = default würth, "m03swg" = swg, "m03swgdev" = swg dev, "m03itw" = itw, "m03itwdev" = itw dev, "m03dev" = würth dev

    laengeneinheit: "cm", // zulaessige werte: "cm" (default), "m", "mm", "inch", "feet". kann geaendert werden oder von aussen (querystring) gesetzt werden
    syslaengeneinheit: "m", // zulaessige werte: "m" (default), "cm", "mm", "inch", "feet". kann geaendert werden oder von aussen (querystring) gesetzt werden
    krafteinheit: "kN", // zulaessige werte: "kN" (default), "N". kann geaendert werden oder von aussen (querystring) gesetzt werden

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    loggedIn: true,

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,
    projektdatenInvalid: false,
    geometrieInvalid: false,
    anschlussgeometrieInvalid: false,
    bauteileInvalid: true,
    lasteinwirkungInvalid: false,
    optionenInvalid: false,
    verbindungsmittelInvalid: true,
    zwischenschichtVerstaerkungInvalid: true,

    verstaerkungInDerGrafik: false,
    verstaerkungWernerInDerGrafik: false,

    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "https://www.timberconnect.de/produktinfo/wuerth/katalog/katalog_de.pdf",
    eta: "https://www.timberconnect.de/produktinfo/wuerth/eta/eta-110190_de.pdf",
    etaitw: "https://www.itw-haubold.de/index.php?id=haubold_technische_datenbltter1",
    helpfile: "https://timberconnect.de/itw/HelpFileITW_DE.pdf", // ITW
    hilfelink: "https://timberconnect.de/hilfe/wuerth/html/de/", //Würth
    dops: "https://www.itwcp-techdocs.eu/DOP_wood.html",
    nutzungsbedingungen: "https://timberconnect.de/itw/termsandconditions_DE.pdf",
    unsaved: true,

    server: "",
    pfad: "",

    werner: false,
    zwverDisabled: true,
    stahl: false,
    zsver: 0,

    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    bemessungsvorschrift: "EC5 (DE)",

    username: '',
    sessionid: '',

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      "DE": "deutsch",
      "DK": "dansk",
      "EN": "englisch",
      "FR": "franzoesisch",
      "IT": "italienisch",
      "NL": "nederlands",
      "PL": "polnisch",
      "ES": "spanisch",
      "FI": "suomi",
      "SE": "schwedisch",
      "PT": "portugiesich",
      "CZ": "tschechisch"
    },

    countries: {
      "CL": "chile",
      "DK": "daenemark",
      "DE": "deutschland",
      "EN": "england",
      "UK": "england",
      "FI": "finnland",
      "FR": "frankreich",
      "IE": "irland",
      "NL": "niederlande",
      "IT": "italien",
      "LU": "luxemburg",
      "HR": "kroatien",
      "NO": "norwegen",
      "AT": "oesterreich",
      "PL": "polen",
      "RO": "rumaenien",
      "SE": "schweden",
      "CH": "schweiz",
      "SK": "slowakei",
      "SI": "slowenien",
      "ES": "spanien",
      "CZ": "tschechien",
      "HU": "ungarn",
      "PT": "portugal",
      "BE": "belgien"

    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    wuerthlogo: true,

    init: function init() {
      var self = this;
      this._super();

      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);

      if (self.get('itw') === true) {
        self.set('lasteinwirkungInvalid', false);
        self.set('bemessungsvorschrift', "EC5 / NA (DE)");
      }

      var username = self.getParameterByName('user_name');
      var sessionid = self.getParameterByName('user_sessionid');

      var environment = externalConfig.environments[self.environment];

      self.setCountryVars();
      self.setFactors();

      self.set('username', username);
      self.set('sessionid', sessionid);

      var server = externalConfig[environment].server;
      var pfad = externalConfig[environment].pfad;
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "POST",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        var authenticationResult = res.split(";");
        console.log(authenticationResult);
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        console.log(authenticationResult);
        if (auth == -1) {
          console.log("login unsuccessful.");
        } else if (auth == 0) {
          self.set('loggedIn', true);
          var applicationdata = self.get('model').application.objectAt(0);
          applicationdata.set('userName', username);
          console.log("login successful!");
        }
        var applicationdata = self.get('model').application.objectAt(0);

        // applicationdata.set('userName', username);
        applicationdata.set('spracheinput', self.get('si'));
        self.send('setSpracheInput', self.get('si'));

        applicationdata.set('spracheoutput', self.get('so'));
        applicationdata.set('kennungland', self.get('kl'));
        // applicationdata.set('countrycode', self.get('kl'));
        applicationdata.set('katalog', self.get('kat'));
        applicationdata.set('loadsCode', self.get('lc'));
        applicationdata.set('loadsNA', self.get('ln'));
        applicationdata.set('timberCode', self.get('tc'));
        applicationdata.set('timberNA', self.get('tn'));

        applicationdata.set('laengenEinheit', self.get('laengeneinheit'));
        applicationdata.set('syslaengenEinheit', self.get('syslaengeneinheit'));
        applicationdata.set('kraefteEinheit', self.get('krafteinheit'));

        self.send('setBemessungsvorschrift', self.get('tn'));

        self.set('selectedCountry', self.get('i18n').t(self.countries[self.get('kl')]));

        $.ajax({
          type: "POST",
          url: server + "initialisiereGUI/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
          data: {}
        }).done(function (data, statusText, xhr) {

          self.controllerFor('supercontroller').logaufruf("initialisiereGUI", xhr.status);

          console.log('initialisiereGUI data: ');
          console.log(data);
        });
      });

      self.transitionToRoute('projektdaten');

      var pd = self.controllerFor('projektdaten');
      var geo = self.controllerFor('geometrie');
      geo.set('laengenFaktor', self.laengenFaktor);
      geo.set('laengenLabel', self.laengenLabel);
      geo.set('laengenNachkomma', self.laengenNachkomma);
      geo.set('syslaengenFaktor', self.laengenFaktor);
      geo.set('syslaengenLabel', self.laengenLabel);
      geo.set('syslaengenNachkomma', self.laengenNachkomma);
      geo.set('kraefteFaktor', self.kraefteFaktor);
      geo.set('kraefteLabel', self.kraefteLabel);
      geo.set('kraefteNachkomma', self.kraefteNachkomma);
      geo.applyFactor("validations");

      var bau = self.controllerFor('bauteile');
      bau.set('laengenFaktor', self.laengenFaktor);
      bau.set('laengenLabel', self.laengenLabel);
      bau.set('laengenNachkomma', self.laengenNachkomma);
      bau.set('syslaengenFaktor', self.laengenFaktor);
      bau.set('syslaengenLabel', self.laengenLabel);
      bau.set('syslaengenNachkomma', self.laengenNachkomma);
      bau.set('kraefteFaktor', self.kraefteFaktor);
      bau.set('kraefteLabel', self.kraefteLabel);
      bau.set('kraefteNachkomma', self.kraefteNachkomma);
      bau.applyFactor("validations");

      bau.set('timbercode', self.get('tc'));

      var anschlGeo = self.controllerFor('anschlussgeometrie');
      anschlGeo.set('laengenFaktor', self.laengenFaktor);
      anschlGeo.set('laengenLabel', self.laengenLabel);
      anschlGeo.set('laengenNachkomma', self.laengenNachkomma);
      anschlGeo.set('syslaengenFaktor', self.laengenFaktor);
      anschlGeo.set('syslaengenLabel', self.laengenLabel);
      anschlGeo.set('syslaengenNachkomma', self.laengenNachkomma);
      anschlGeo.set('kraefteFaktor', self.kraefteFaktor);
      anschlGeo.set('kraefteLabel', self.kraefteLabel);
      anschlGeo.set('kraefteNachkomma', self.kraefteNachkomma);
      anschlGeo.applyFactor("validations");

      var lw = self.controllerFor('lasteinwirkung');

      if (this.get('swg') === true) {
        lw.validations.scherkraft.numericality.greaterThanOrEqualTo = 0;
      }

      lw.set('laengenFaktor', self.laengenFaktor);
      lw.set('laengenLabel', self.laengenLabel);
      lw.set('laengenNachkomma', self.laengenNachkomma);
      lw.set('kraefteFaktor', self.kraefteFaktor);
      lw.set('kraefteLabel', self.kraefteLabel);
      lw.set('kraefteNachkomma', self.kraefteNachkomma);

      lw.applyFactor("validations");

      var vb = self.controllerFor('verbindungsmittel');
      var im = self.controllerFor('impressum');
      var zwv = self.controllerFor('zwischenschichtverstaerkung');
      zwv.set('laengenFaktor', self.laengenFaktor);
      zwv.set('laengenLabel', self.laengenLabel);
      zwv.set('laengenNachkomma', self.laengenNachkomma);
      console.log('Lenge Nachkomma aus Apllication controller: ' + zwv.get('laengenNachkomma'));

      zwv.set('kraefteFaktor', self.kraefteFaktor);
      zwv.set('kraefteLabel', self.kraefteLabel);
      zwv.set('kraefteNachkomma', self.kraefteNachkomma);
      zwv.applyFactor("validations");
      // var dlc = self.controllerFor('downloadcenter');

      if (self.itw) {
        pd.set('itw', true);
        geo.set('itw', true);
        geo.set('zwvsauswahlanzeigen', true);
        bau.set('itw', true);
        bau.set('firm', 'ITW');
        lw.set('itw', true);
        vb.set('itw', true);
        im.set('itw', true);
        im.set('wuerth', false);
        im.set('swg', false);
        zwv.set('itw', true);
        zwv.set('firm', 'ITW');
        // dlc.set('itw', true);
        self.set('server', externalConfig[environment].server);
        self.set('pfad', externalConfig[environment].pfad);

        console.log(self.get('server'));
        console.log(self.get('pfad'));
      } else if (self.swg) {
        pd.set('swg', true);
        geo.set('swg', true);
        geo.set('zwvsauswahlanzeigen', true);
        bau.set('swg', true);
        bau.set('firm', 'SWG');
        lw.set('swg', true);
        vb.set('swg', true);
        im.set('swg', true);
        im.set('wuerth', false);
        zwv.set('swg', true);
        zwv.set('firm', 'SWG');
        // dlc.set('swg', true);

        self.set('server', externalConfig[environment].server);
        self.set('pfad', externalConfig[environment].pfad);

        console.log('server + pfad');
        console.log(self.get('server'));
        console.log(self.get('pfad'));
      } else {

        geo.set('zwvsauswahlanzeigen', true);

        //online
        self.set('server', externalConfig[environment].server);
        self.set('pfad', externalConfig[environment].pfad);

        console.log(self.get('server'));
        console.log(self.get('pfad'));
      }

      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);
    },

    getParameterByName: function getParameterByName(name, url) {

      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {

      var self = this;

      var si = self.getParameterByName('spracheinput');
      var so = self.getParameterByName('spracheoutput');
      var kl = self.getParameterByName('kennungland');
      var kat = self.getParameterByName('katalog');
      var lc = self.getParameterByName('loadsCode');
      var ln = self.getParameterByName('loadsNA');
      var tc = self.getParameterByName('timberCode');
      var tn = self.getParameterByName('timberNA');

      if (window.location.href.indexOf("timberCode") === -1) {
        self.set('si', "DE");
        self.set('so', "DE");
        self.set('kl', "DE");
        self.set('kat', "DE");
        // self.set('lc', "EC 1 NAD (DE)");
        self.set('lc', "EC1");
        self.set('ln', "DE");
        // self.set('tc', "EC 5 NAD (DE)");
        self.set('tc', "EC5");
        self.set('tn', "DE");
        // self.set('bemessungsvorschrift', "EC5 (DE)");

        if (self.get('itw') === true) {
          self.set('bemessungsvorschrift', "EC5 / NA (DE)");
        } else {
          self.set('bemessungsvorschrift', "EC5 (DE)");
        }
      } else {
        self.set('si', si);
        self.set('so', so);
        self.set('kl', kl);
        self.set('kat', kat);
        self.set('lc', lc);
        self.set('ln', ln);
        self.set('tc', tc);
        self.set('tn', tn);

        var praefix = "EC5 ";

        if (self.get('itw') === true) {
          praefix = "EC5 / NA ";
        }

        if (tc.slice(10, tc.length - 1).length === 2) {
          self.set('bemessungsvorschrift', praefix + "(" + tc.slice(10, tc.length - 1) + ")");
        } else {
          if (tc.slice(10, tc.length - 1) === "D") {
            self.set('bemessungsvorschrift', praefix + "(DE)");
          } else if (tc.slice(10, tc.length - 1) === "A") {
            self.set('bemessungsvorschrift', praefix + "(AT)");
          } else if (tc.slice(10, tc.length - 1) === "I") {
            self.set('bemessungsvorschrift', praefix + "(IT)");
          } else if (tc.slice(10, tc.length - 1) === "F") {
            self.set('bemessungsvorschrift', praefix + "(FR)");
          }
        }

        //self.send('setSpracheInput', si);
      }
    },

    setFactors: function setFactors() {

      var self = this;

      var laengeneinheit = self.get('laengeneinheit');
      var syslaengeneinheit = self.get('syslaengeneinheit');
      var krafteinheit = self.get('krafteinheit');

      var laengenDB = einheitenDB.laengen;
      var kraefteDB = einheitenDB.kraefte;

      self.set('laengenFaktor', laengenDB[laengeneinheit].faktor);
      self.set('laengenLabel', laengenDB[laengeneinheit].label);
      self.set('laengenNachkomma', laengenDB[laengeneinheit].nachkommastellen);
      self.set('syslaengenFaktor', laengenDB[syslaengeneinheit].faktor);
      self.set('syslaengenLabel', laengenDB[syslaengeneinheit].label);
      self.set('syslaengenNachkomma', laengenDB[syslaengeneinheit].nachkommastellen);
      self.set('kraefteFaktor', kraefteDB[krafteinheit].faktor);
      self.set('kraefteLabel', kraefteDB[krafteinheit].label);
      self.set('kraefteNachkomma', kraefteDB[krafteinheit].nachkommastellen);

      console.log(self.get('laengenFaktor'));
      console.log(self.get('laengenLabel'));
      console.log(self.get('laengenNachkomma'));
      console.log(self.get('syslaengenFaktor'));
      console.log(self.get('syslaengenLabel'));
      console.log(self.get('syslaengenNachkomma'));
      console.log(self.get('kraefteFaktor'));
      console.log(self.get('kraefteLabel'));
      console.log(self.get('kraefteNachkomma'));
    },

    setX3dUmrechnugsFaktoren: function setX3dUmrechnugsFaktoren() {

      var self = this;

      var laengeneinheit = self.get('laengeneinheit');
      var syslaengeneinheit = self.get('syslaengeneinheit');
      var krafteinheit = self.get('krafteinheit');

      var laengenDB = einheitenDB.laengen;
      var kraefteDB = einheitenDB.kraefte;

      var x3d = this.get('model').x3ddefault.objectAt(0);

      x3d.set('laengenFaktor', laengenDB[laengeneinheit].faktor);
      x3d.set('laengenLabel', laengenDB[laengeneinheit].label);
      x3d.set('laengenNachkomma', laengenDB[laengeneinheit].nachkommastellen);
      x3d.set('syslaengenFaktor', laengenDB[syslaengeneinheit].faktor);
      x3d.set('syslaengenLabel', laengenDB[syslaengeneinheit].label);
      x3d.set('syslaengenNachkomma', laengenDB[syslaengeneinheit].nachkommastellen);
      x3d.set('kraefteFaktor', kraefteDB[krafteinheit].faktor);
      x3d.set('kraefteLabel', kraefteDB[krafteinheit].label);
      x3d.set('kraefteNachkomma', kraefteDB[krafteinheit].nachkommastellen);
    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB.fkl_stahl;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["fklind"] === index) {
          tmp = pklassenDB[i]["fkl"];
        }
      }
      return tmp;
    },

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      self.transitionToRoute('projektdaten');
    },

    actions: {

      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;

        this.get('i18n').set('locale', spracheInput.toLowerCase());
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('spracheinput', spracheInput);
        applicationdata.set('spracheoutput', spracheInput);
        self.send('setCountryCode', spracheInput);

        self.set('selectedLanguage', self.get('i18n').t(self.languages[spracheInput]));

        this.controllerFor('impressum').set('selectedLanguage', spracheInput);

        this.controllerFor('geometrie').setSelectFieldsContent();
        this.controllerFor('bauteile').setSelectFieldsContent();
        this.controllerFor('anschlussgeometrie').setSelectFieldsContent();
        this.controllerFor('zwischenschichtverstaerkung').setSelectFieldsContent();
        this.controllerFor('lasteinwirkung').setSelectFieldsContent();

        this.controllerFor('projektdaten').set("initTrigger", !this.controllerFor('projektdaten').get("initTrigger"));
        this.controllerFor('geometrie').set("initTrigger", !this.controllerFor('geometrie').set("initTrigger"));
        this.controllerFor('bauteile').set("initTrigger", !this.controllerFor('bauteile').set("initTrigger"));
        this.controllerFor('anschlussgeometrie').set("initTrigger", !this.controllerFor('anschlussgeometrie').set("initTrigger"));
        this.controllerFor('zwischenschichtverstaerkung').set("initTrigger", !this.controllerFor('zwischenschichtverstaerkung').set("initTrigger"));
        this.controllerFor('lasteinwirkung').set("initTrigger", !this.controllerFor('lasteinwirkung').set("initTrigger"));
        this.controllerFor('optionen').set("initTrigger", !this.controllerFor('optionen').set("initTrigger"));

        self.set('helpfile', 'https://timberconnect.de/itw/HelpFileITW_' + spracheInput + '.pdf');

        if (spracheInput == "DE") {
          self.set('nutzungsbedingungen', 'https://timberconnect.de/itw/termsandconditions_DE.pdf'); // ITW
          self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/de/m03___scher___und_axialverbindung_.html?ms=AAAA&st=MA%3D%3D&sct=MA%3D%3D&mw=MzAw#'); // Würth
        } else {
            self.set('nutzungsbedingungen', 'https://timberconnect.de/itw/termsandconditions_EN.pdf'); // ITW
            self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/en/m03___axial_and_shear_connection.html?ms=AAAA&st=MA%3D%3D&sct=MA%3D%3D&mw=MzAw#'); // Würth
          }

        self.controllerFor('geometrie').setTooltips("validations");
        self.controllerFor('bauteile').setTooltips("validations");
        self.controllerFor('zwischenschichtverstaerkung').setTooltips("validations");
        self.controllerFor('lasteinwirkung').setTooltips("validations");

        self.resetApplication();
      },

      setCountryCode: function setCountryCode(countryCode) {

        countryCode = countryCode === "EN" ? "UK" : countryCode;

        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);
        // applicationdata.set('countrycode', countryCode);
        applicationdata.set('kennungland', countryCode);

        var firm = applicationdata.get('firm');

        var projektdaten = this.controllerFor('projektdaten');

        if (countryCode === "DE") {
          projektdaten.set('plzservice', true);
        } else {
          projektdaten.set('plzservice', false);
        }

        var downloadcenter = this.controllerFor('downloadcenter');

        var cC = countryCode;
        var eta = "120196";

        if (typeof linksDB.firm.findBy('id', firm) === "undefined") {
          firm = "SWG";
        }

        if (typeof linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC) === "undefined") {
          cC = "EN";
        }

        if (firm === "WUERTH") {
          eta = "110190";
        }

        var prodkaturl = "";
        var etaurl = "";

        if (self.get('environment') === "offline") {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").offline;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).offline;
        } else {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").file;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).file;
        }

        this.set('eta', etaurl);
        this.set('produktkatalog', prodkaturl);
        downloadcenter.set('eta', etaurl);
        downloadcenter.set('produktkatalog', prodkaturl);

        self.set('selectedCountry', self.get('i18n').t(self.countries[countryCode]));

        self.set('wuerthlogo', cC === "DE" ? true : false);

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get('model').application.objectAt(0);
        // applicationdata.set('countrycode', countryCode);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set('eta', etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT" || bemessungsvorschrift === "SE") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
          // if (self.itw) {
          //   this.set('bemessungsvorschrift', "EC 5 / NA (" + bemessungsvorschrift + ")");
          // } else {
          //   this.set('bemessungsvorschrift', "EC5 NAD (" + bemessungsvorschrift + ")");
          // }
        } else {
            if (bemessungsvorschrift === "DK" || bemessungsvorschrift === "FI") {
              // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('loadsNA', "DE");
              applicationdata.set('timberNA', bemessungsvorschrift);
              // if (self.itw) {
              //   this.set('bemessungsvorschrift', "EC 5 / NA (" + bemessungsvorschrift + ")");
              // } else {
              //   this.set('bemessungsvorschrift', "EC5 NAD (" + bemessungsvorschrift + ")");
              // }
            } else {
                // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
                if (bemessungsvorschrift === "IT") {
                  // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
                  applicationdata.set('loadsNA', "DE");
                  // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
                  applicationdata.set('timberNA', bemessungsvorschrift);
                } else if (bemessungsvorschrift === "FR") {
                  // applicationdata.set('loadsCode', "EC 1 NAD ("+ bemessungsvorschrift +")");
                  applicationdata.set('loadsNA', bemessungsvorschrift);
                  // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
                  applicationdata.set('timberNA', bemessungsvorschrift);
                }
                // if (self.itw) {
                //   this.set('bemessungsvorschrift', "EC 5 / NA (" + bemessungsvorschrift.substring(0, 1) + ")");
                // } else {
                //   this.set('bemessungsvorschrift', "EC5 NAD (" + bemessungsvorschrift.substring(0, 1) + ")");
                // }
              }
          }

        if (bemessungsvorschrift === "CZ") {
          // applicationdata.set('loadsCode', "EC 1 NAD (CZ)");
          applicationdata.set('loadsNA', "CZ");
          // applicationdata.set('timberCode', "EC 5 NAD (CZ)");
          applicationdata.set('timberNA', "CZ");

          // if (self.itw) {
          //   this.set('bemessungsvorschrift', "EC 5 / NA (CZ)");
          // } else {
          //   this.set('bemessungsvorschrift', "EC5 NAD (CZ)");
          // }
        } else if (bemessungsvorschrift === "NL") {
            // applicationdata.set('loadsCode', "EC 1 NAD (NL)");
            applicationdata.set('loadsNA', "NL");
            // applicationdata.set('timberCode', "EC 5 NAD (NL)");
            applicationdata.set('timberNA', "NL");

            // if (self.itw) {
            //   this.set('bemessungsvorschrift', "EC 5 / NA (NL)");
            // } else {
            //   this.set('bemessungsvorschrift', "EC5 NAD (NL)");
            // }
          } else if (bemessungsvorschrift === "UK") {
              // applicationdata.set('loadsCode', "EC 1 NAD (UK)");
              applicationdata.set('loadsNA', "UK");
              // applicationdata.set('timberCode', "EC 5 NAD (UK)");
              applicationdata.set('timberNA', "UK");

              // if (self.itw) {
              //   this.set('bemessungsvorschrift', "EC 5 / NA (UK)");
              // } else {
              //   this.set('bemessungsvorschrift', "EC5 NAD (UK)");
              // }
            } else if (bemessungsvorschrift === "EC5") {
                // applicationdata.set('loadsCode', "EC 1 NAD (XX)");
                applicationdata.set('loadsNA', "XX");
                // applicationdata.set('timberCode', "EC 5 NAD (XX)");
                applicationdata.set('timberNA', "XX");

                // if (self.itw) {
                //   this.set('bemessungsvorschrift', "EC 5 / NA");
                // } else {
                //   this.set('bemessungsvorschrift', "EC5 NAD (XX)");
                // }
              }

        applicationdata.set('loadsCode', "EC1");
        applicationdata.set('timberCode', "EC5");

        if (bemessungsvorschrift === "EC5") {
          this.set('bemessungsvorschrift', "EC5");
        } else {
          if (self.get('itw') === true) {
            this.set('bemessungsvorschrift', "EC5 / NA (" + bemessungsvorschrift + ")");
          } else {
            this.set('bemessungsvorschrift', "EC5 (" + bemessungsvorschrift + ")");
          }
        }

        var bau = this.controllerFor('bauteile');
        var zws = this.controllerFor('zwischenschichtverstaerkung');
        bau.set('timberCode', applicationdata.get('timberCode'));
        zws.set('timberCode', applicationdata.get('timberCode'));
        var matopt = bau.get('materialienOption');
        bau.set('materialienOption', -1);
        bau.set('materialienOption', matopt);

        this.controllerFor('lasteinwirkung').set('timberNA', bemessungsvorschrift);

        this.controllerFor('bauteile').setSelectFieldsContent();
        this.controllerFor('zwischenschichtverstaerkung').setSelectFieldsContent();
        this.controllerFor('lasteinwirkung').setSelectFieldsContent();

        self.resetApplication();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute('downloadcenter');
      },

      verbindungsmittel: function verbindungsmittel(event) {
        var lasteinwirkung = this.controllerFor('lasteinwirkung');
        var applicationdata = this.get('model').application.objectAt(0);

        if (!applicationdata.get('treeLoaded')) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          lasteinwirkung.send('proceedInput');
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        var verbindungsmittel = this.controllerFor('verbindungsmittel');

        verbindungsmittel.send('proceedInput');
      },

      newAction: function newAction(event) {
        /*console.log(window.location.href);
        var self = this;
        var protocol = window.location.protocol;
        var host = window.location.host;
         window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid');*/

        console.log('event:');
        console.log(event);
        console.log(window.location.href);

        var self = this;

        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get('model').application.objectAt(0);
        var si = applicationdata.get('spracheinput');
        var so = applicationdata.get('spracheoutput');
        var kl = applicationdata.get('kennungland');
        var kat = applicationdata.get('katalog');
        var lc = applicationdata.get('loadsCode');
        var ln = applicationdata.get('loadsNA');
        var tc = applicationdata.get('timberCode');
        var tn = applicationdata.get('timberNA');
        var bv = self.get('bemessungsvorschrift');

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid') + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      etaAnzeigen: function etaAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      setCameraActiv: function setCameraActiv(camera) {
        var self = this;
        var lastCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', this.get('model').x3ddefault.objectAt(0).get('lastViewpoint'));

        this.get('model').x3ddefault.objectAt(0).set('lastViewpoint', camera);

        var activeCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', camera);

        lastCamera.get('cameraProperties').objectAt(0).set('setBind', false);
        activeCamera.get('cameraProperties').objectAt(0).set('setBind', true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück
        self.zentriertObjekt();

        this.get('model').x3ddefault.objectAt(0).set('viewpointHelper', !this.get('model').x3ddefault.objectAt(0).get('viewpointHelper'));
        this.get('model').x3ddefault.objectAt(0).set('masskettenHelper', !this.get('model').x3ddefault.objectAt(0).get('masskettenHelper'));
        // this.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get('model').x3ddefault.objectAt(0).set('istGitterModell', !this.get('model').x3ddefault.objectAt(0).get('istGitterModell'));
      },

      saveAction: function saveAction() {

        console.log('speichern()');

        var self = this;

        self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor('downloadcenter');
        dc.set('savingInProgress', true);

        var application = self.controllerFor('application');

        var bauteile = self.controllerFor('bauteile');

        var applicationdata = application.get('model').application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get('model').projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);
        //
        // console.log(projektdatendata);
        // console.log(projektdatendataJSON);
        //
        // var geometriedata = application.get('model').geometrie.objectAt(0);
        // var geometriedataJSON = JSON.stringify(geometriedata);
        //
        // var bauteiledata = application.get('model').bauteile.objectAt(0);
        // var bauteiledataJSON = JSON.stringify(bauteiledata);
        //
        // var zwverdata = application.get('model').zwischenschichtverstaerkung.objectAt(0);
        // var zwverdataJSON = JSON.stringify(zwverdata);
        //
        // var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
        // var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);
        //
        // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
        // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
        //
        // var optionendata = application.get('model').optionen.objectAt(0);
        // var optionendataJSON = JSON.stringify(optionendata);
        //
        // if (self.debug) {
        //   console.log("Kennung: " + applicationdataJSON);
        //   console.log("Projektdaten: " + projektdatendataJSON);
        //   console.log("bauteiledataJSON: " + bauteiledataJSON);
        //   console.log("geometriedataJSON: " + geometriedataJSON);
        //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
        // }
        //
        // var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(zwverdataJSON), JSON.parse(anschlussgeometriedataJSON), JSON.parse(lasteinwirkungdataJSON), JSON.parse(optionendataJSON));
        //
        // var shearconnection = {
        //   "ConnectionTyp": geometriedata.get('ConnectionType'),
        //   "von1zu2": true,
        //   "ZwischenSchichtVorh": geometriedata.get('ZwischenSchichtVorh'),
        //   "VerstaerkungVorh": geometriedata.get('VerstaerkungVorh'),
        //   "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
        //   "Setztiefe": Number(geometriedata.get('Setztiefe')).toFixed(1),
        //   "ShowMessageOnError": false,
        //   "StarreZwischenSchicht": zwverdata.get('StarreZwischenSchicht'),
        //   "Klammer30": geometriedata.get('Klammer30')
        // };
        //
        //
        // if (self.get('stahl')) {
        //   var timberelement1 = {
        //     "Querschnittswerte": {
        //       "t_Fastener": bauteiledata.get('TimberElement2_b'),
        //       "b": bauteiledata.get('TimberElement2_b'),
        //       "h": bauteiledata.get('TimberElement2_h')
        //     },
        //     "ALFA_RAD_LoadGrain": "0.0",
        //     "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
        //     "MatKey": bauteiledata.get('MatKeyTimberElement2'),
        //     "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
        //   };
        // } else {
        //   var timberelement1 = {
        //     "Querschnittswerte": {
        //       "QuerschnittsID": "1",
        //       "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
        //       "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
        //       "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(2)
        //     },
        //     "ALFA_RAD_LoadGrain": "0.0",
        //     "vorgebohrt": bauteiledata.get('TimberElement1Predrilled'),
        //     "MatKey": bauteiledata.get('MatKeyTimberElement1'),
        //     "FKLKey": bauteiledata.get('FKLKeyTimberElement1')
        //   };
        // }
        //
        // var timberelement2 = {
        //   "Querschnittswerte": {
        //     "QuerschnittsID": "1",
        //     "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
        //     "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
        //     "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(2)
        //   },
        //   "ALFA_RAD_LoadGrain": "0.0",
        //   "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
        //   "MatKey": bauteiledata.get('MatKeyTimberElement2'),
        //   "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
        // };
        //
        // if (self.get('zsver') == 0) {
        //   var interlayerelement = {
        //     "Querschnittswerte": {
        //       "QuerschnittsID": "1",
        //       "t_Fastener": 0.0,
        //       "b": 0.0,
        //       "h": 0.0
        //     },
        //     "ALFA_RAD_LoadGrain": 0.0,
        //     "vorgebohrt": false,
        //     "MatKey": 0,
        //     "FKLKey": 0
        //   };
        // } else if (self.get('zsver') == 1) {
        //   var interlayerelement = {
        //     "Querschnittswerte": {
        //       "QuerschnittsID": "1",
        //       "t_Fastener": zwverdata.get('dickeBlass'),
        //       "b": zwverdata.get('dickeBlass'),
        //       "h": 0.0
        //     },
        //     "ALFA_RAD_LoadGrain": 0.0,
        //     "vorgebohrt": zwverdata.get('materialBlassPredrilled'),
        //     "MatKey": zwverdata.get('materialBlass'),
        //     "FKLKey": zwverdata.get('fklBlass')
        //   };
        // } else if (self.get('zsver') == 2) {
        //   var interlayerelement = {
        //     "Querschnittswerte": {
        //       "QuerschnittsID": "1",
        //       "t_Fastener": zwverdata.get('dickeWerner'),
        //       "b": zwverdata.get('dickeWerner'),
        //       "h": 0.0
        //     },
        //     "ALFA_RAD_LoadGrain": 0.0,
        //     "vorgebohrt": zwverdata.get('materialWernerPredrilled'),
        //     "MatKey": zwverdata.get('materialWerner'),
        //     "FKLKey": zwverdata.get('fklWerner')
        //   };
        // }
        //
        // if(bauteile.get('htStahl') === true){
        //   var steelplate = {
        //     "Bezeichnung": self.getStahlname(Number(bauteile.get('htfkl'))),
        //     "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
        //     "Querschnittswerte": {
        //       "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
        //       "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
        //       "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(2)
        //     },
        //     "ALFA_RAD_LoadGrain": 0.0,
        //     "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
        //     "MatKey": bauteiledata.get('MatKeyTimberElement2'),
        //     "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
        //   };
        // }else{
        //   var steelplate = {
        //     "Bezeichnung": self.getStahlname(Number(bauteile.get('ntfkl'))),
        //     "f_yk": Number(bauteile.get('ntfkl')).toFixed(1),
        //     "Querschnittswerte": {
        //       "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
        //       "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
        //       "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(2)
        //     },
        //     "ALFA_RAD_LoadGrain": 0.0,
        //     "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
        //     "MatKey": bauteiledata.get('MatKeyTimberElement2'),
        //     "FKLKey": bauteiledata.get('FKLKeyTimberElement2')
        //   };
        // }
        //
        // var berechnungsoptionen = {
        //   "faserversatz": optionendata.get('verbindungsmittelVersetzt')
        // }
        //
        // uebergabedaten.ShearConnection = shearconnection;
        // uebergabedaten.TimberElement1 = timberelement1;
        // uebergabedaten.TimberElement2 = timberelement2;
        // uebergabedaten.InterlayerElement = interlayerelement;
        // uebergabedaten.SteelPlate = steelplate;
        // uebergabedaten.berechnungsoptionen = berechnungsoptionen;
        //
        // var JSONdata = JSON.stringify(uebergabedaten);
        var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

        var initialized = applicationdata.get('initialized');

        var parasToSave = JSON.parse(JSONdata);

        var dataToSave = JSON.stringify(auxiliary.save([parasToSave]));

        // console.log(JSON.stringify(dataToSave));

        //var dingsbums = JSON.stringify(dataToSave);

        if (self.debug) {
          console.log("Kennung: " + applicationdataJSON);
          console.log(JSON.parse(JSON.stringify(applicationdata)));
          console.log(' ');
          console.log('#######################################');
          console.log(' ');
          console.log("Projektdaten: " + projektdatendataJSON);
          console.log(JSON.parse(JSON.stringify(projektdatendata)));
          console.log(' ');
          console.log('#######################################');
          console.log(' ');
          console.log("JSONdata: " + JSONdata);
          console.log(JSON.parse(JSONdata));
          console.log('#######################################');
          console.log(' ');
          console.log("Zu speichernde Daten: " + dataToSave);
          console.log(JSON.parse(dataToSave));
        }

        var server = self.get('server');
        var pfad = self.get('pfad');

        console.log('server: ' + server);
        console.log('pfad: ' + pfad);

        if (!initialized) {

          $.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
            //url: "https://136.243.4.143/berechnungsservice/x3d/DE/erzeugeSchraubenliste/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m03",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;

            console.log('cid: ' + cid);

            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            dc.set('cid', data);

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {

            var cid = applicationdata.get('Calculation_ID');

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }
        self.set('unsaved', false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {

        var self = this;

        self.setX3dUmrechnugsFaktoren();

        if (self.xml) {
          var test = self.parseXml(file.data);
          // console.log('test');
          // console.log(xml2json(test, ""));
        } else {
            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);

            var projektdaten = self.controllerFor('projektdaten');
            var lasteinwirkung = self.controllerFor('lasteinwirkung');
            var bauteile = self.controllerFor('bauteile');
            var anschlussgeometrie = self.controllerFor('anschlussgeometrie');
            var geometrie = self.controllerFor('geometrie');
            var zsver = self.controllerFor('zwischenschichtverstaerkung');
            var optionen = self.controllerFor('optionen');

            var values = JSON.parse(file.data);

            self.send('setSpracheInput', values.spracheinput);

            applicationdata.set('katalog', values.katalog);

            self.send('setBemessungsvorschrift', values.timberNA);

            // applicationdata.set('countrycode', values.countrycode );
            applicationdata.set('kennungland', values.kennungland);
            applicationdata.set('userID', values.userID);
            applicationdata.set('userName', values.userName);

            console.log('***** Gespeicherte Daten setzen *****');

            projektdaten.setValues(JSON.parse(file.data));
            lasteinwirkung.setValues(JSON.parse(file.data));
            geometrie.setValues(JSON.parse(file.data));
            bauteile.setValues(JSON.parse(file.data));
            zsver.setValues(JSON.parse(file.data));
            anschlussgeometrie.setValues(JSON.parse(file.data));
            optionen.setValues(JSON.parse(file.data));
          }

        self.transitionToRoute('projektdaten');
      },

      toggleForm: function toggleForm() {
        $('.clearfix').toggleClass('formZu');
        $('#formToggler').toggleClass('open');

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById('x3d').runtime;

          // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }, 400);
      },

      getTestGrafik: function getTestGrafik() {

        var self = this;

        console.log('Testgrafik');

        var application = self.controllerFor('application');
        var x3d = application.get('model').x3ddefault.objectAt(0);
        var store = application.get('store');
        var arrayHelper = [];

        // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
        application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if (item.get('typ') === 'verbindungsmittel' || item.get('typ') === 'masskette' || item.get('typ') === 'platte') {
            arrayHelper.pushObject(item);
          }
        });
        arrayHelper.forEach(function (item) {
          application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
        });

        var server = application.get('server');
        var pfad = application.get('pfad');

        var x3d = application.get('model').x3ddefault.objectAt(0);

        $.ajax({
          // url: "m03grafik20170804.json",
          // url: "m03_bsp.json",
          // url: "m03_bsp90Grad.json",
          // url: "jsontest.json",
        }).done(function (data) {

          console.log('Daten der ausgewählten Schraube');
          console.log(data);

          var dataObject = data;

          dataObject.bauteile.forEach(function (item) {

            var newID = item.id;
            if (item.typ === "verbindungsmittel") {
              newID = "schraubeStandard1";
            } else if (item.typ === "platte") {
              if (item.id.substring(0, 13) === 'seitenbauteil' && x3d.get('sFolgeHolzHolz') === false) {
                newID = "metall";
              } else if (item.id.substring(0, 16) === 'verstaerkunggurt') {
                newID = "verstaerkungBlass1";
              } else if (item.id.substring(0, 21) === 'verstaerkungdiagonale') {
                newID = "verstaerkungWerner1";
              } else {
                newID = "holz";
              }
            }

            if (item.typ === "verbindungsmittel") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: [item.point],
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                screwProperties: [item.verbindungsmittel],
                richtungsvektor: [item.richtungsvektor],
                lagewinkel: [item.lagewinkel]
              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            } else if (item.typ === "platte") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: item.point,
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                richtungsvektor: [item.richtungsvektor],
                orthovektor: [item.orthovektor],
                platte: [item.id]
              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            } else if (item.typ === "masskette") {

              var bauteil = store.push('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: item.point,
                translations: ["null"],
                rotations: ["null"],
                appearances: [newID],
                diffusecolors: [newID],
                richtungsvektor: [item.richtungsvektor],
                // orthovektor: [item.orthovektor],
                masskette: [item.id],
                ansichtsvektoren: [item.offsetVektorAnsichtXY, item.offsetVektorAnsichtXZ, item.offsetVektorAnsichtYZ]

              });

              bauteil.save();
              x3d.get('bauteile').pushObject(bauteil);
            }
          });

          var xWerte = [];
          var yWerte = [];
          var zWerte = [];

          console.log('punkte');

          dataObject.punkte.forEach(function (item) {

            xWerte.push(Number(item.x));
            yWerte.push(Number(item.y));
            zWerte.push(Number(item.z));

            var p = store.push('punkt', item);
            p.save();
          });

          application.setMinMaxWerte("x", xWerte);
          application.setMinMaxWerte("y", yWerte);
          application.setMinMaxWerte("z", zWerte);

          dataObject.platte.forEach(function (item) {
            var p = store.push('platte', item);
            p.save();
          });

          dataObject.richtungsvektoren.forEach(function (item) {
            var r = store.push('richtungsvektor', item);
            r.save();
          });

          dataObject.ansichtsvektoren.forEach(function (item) {
            var r = store.push('ansichtsvektoren', item);
            r.save();
          });

          dataObject.orthovektoren.forEach(function (item) {
            var r = store.push('orthovektor', item);
            r.save();
          });

          dataObject.verbindungsmittel.forEach(function (item) {
            var screwProp = store.push('screw-property', item);
            screwProp.save();
          });

          x3d.set('ebene', 1);
          dataObject.masskette.forEach(function (item) {
            var massk = store.push('masskette', item);
            var textEbene = x3d.get('ebene');

            // if (textEbene === 1 && massk.get('masstext') !== "" && massk.get('masstext') !== "0.00" && massk.get('masstext') !== "0.0"){
            //   x3d.set('ebene', 2)
            // }else if (textEbene === 2 && massk.get('masstext') !== "" && massk.get('masstext') !== "0.00" && massk.get('masstext') !== "0.0"){
            //   x3d.set('ebene', 1)
            // }
            //
            // massk.set('ebene', textEbene)

            massk.save();
          });

          dataObject.lagewinkel.forEach(function (item) {
            var lagewinkel = store.push('lagewinkel', item);
            lagewinkel.save();
          });

          x3d.set('ergebnisGeladen', true);
          x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('transformHelper', !x3d.get('transformHelper'));
          self.controllerFor('application').zentriertObjekt();
        });
      }

    },

    miniertX3D: function miniertX3D() {
      $('.clearfix').removeClass('formZu');
      $('#formToggler').removeClass('open');

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");
        var canvas = document.getElementsByTagName("canvas");
        var x3druntime = document.getElementById('x3d').runtime;

        // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
        x3d.setAttribute('height', Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {

      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    plattenListe: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var platesArray = Ember['default'].Set.create();

      bauteile.forEach(function (item) {
        if (item.get('typ') === "platte") {
          platesArray.add(item);
        }
      });

      return platesArray.toArray();
    }).property('model.x3ddefault.firstObject.transformHelper'),

    ergebnisMassketten: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var masskettenArray = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen') === true) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "masskette" && item.get('masskette').objectAt(0).get('masstext') !== "0.00" && item.get('masskette').objectAt(0).get('masstext') !== "0.0") {

            // if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Parallel') !== -1)) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Senkrecht') !== -1) && (item.id.indexOf('Diagonale') === -1 || Number(x3d.get('vWinkel') === 0))) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Senkrecht') !== -1 || item.id.indexOf('Parallel') !== -1)) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
            //   masskettenArray.add(item);
            // }

            masskettenArray.add(item);
          }
        });
      }

      return masskettenArray.toArray();
    }).property('model.x3ddefault.firstObject.schraubenlisteAktualisiert', 'model.x3ddefault.firstObject.viewpointHelper', 'model.x3ddefault.firstObject.lastViewpoint'),

    ergebnisGeladen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('ergebnisGeladen');
      // value = false;
      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen'),

    abstaendeAnzeigen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('abstaendeAnzeigen');
      return value;
    }).property('model.x3ddefault.firstObject.abstaendeAnzeigen'),

    axialkraftEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (x3d.get('axialkraftEingetragen') && Number(x3d.get('axialkraft')) !== '0.0') {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.axialkraftEingetragen', 'model.x3ddefault.firstObject.ergebnisGeladen'),

    scherkraftEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('scherkraftEingetragen') && Number(x3d.get('scherkraft')) !== '0.0' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.scherkraftEingetragen', 'model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.lastViewpoint'),

    vWinkelEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('itw') == true && (x3d.get('ergebnisGeladen') !== true && x3d.get('vWinkelEingetragen') && Number(x3d.get('vWinkel')) !== 0 && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      } else if (this.get('itw') === false && (x3d.get('ergebnisGeladen') !== true && x3d.get('phiEingetragen') && Number(x3d.get('phi')) !== 0 && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.phi', 'model.x3ddefault.firstObject.phiEingetragen', 'model.x3ddefault.firstObject.vWinkel', 'model.x3ddefault.firstObject.vWinkelEingetragen', 'model.x3ddefault.firstObject.lastViewpoint'),

    vWinkelScherkraftEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('vWinkelScherkraftEingetragen') && x3d.get('scherkraftEingetragen') && Number(x3d.get('vWinkelScherkraft')) !== 0 && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.vWinkelScherkraftEingetragen', 'model.x3ddefault.firstObject.vWinkelScherkraft', 'model.x3ddefault.firstObject.lastViewpoint', 'model.x3ddefault.firstObject.scherkraftEingetragen'),

    zentriertObjekt: function zentriertObjekt() {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = 2;

      var x = 0,
          y = 0,
          z = 0;

      var hoeheMasskette = 0.5;
      var textgroesse = 0.3 * Number(x3d.get('skalierungsfaktor'));
      var winkelBegrenzung = 1;
      var laengeLastenpfeil = 1.5;

      if (x3d.get('ergebnisGeladen') === true) {

        var dimensionen = x3d.get('dimensionen');

        x = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('x'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('x'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil);
        y = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('y'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('y'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil) * Math.sin(x3d.get('vWinkel') * Math.PI / 180);
        z = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('z'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('z'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil);
      } else {

        x = 2 * (x3d.get('xMittelbauteil') / 10 + x3d.get('xSeitenbauteil') / 10 + textgroesse + hoeheMasskette) + winkelBegrenzung;
        var y1 = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10;
        var y2 = Math.sin(x3d.get('vWinkel') * Math.PI / 180) * (laengeLastenpfeil + winkelBegrenzung + textgroesse + hoeheMasskette + Number(x3d.get('xMittelbauteil')) + Number(x3d.get('xSeitenbauteil'))) / 10;
        y = this.arrayAbsteigendSortieren([y1, y2])[0];
        z = 2 * bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;

        // console.log(' ');
        // console.log('ergebnisGeladen === false');
        // console.log('x: '+x);
        // console.log('y: '+y);
        // console.log('z: '+z);
      }

      var arr = [];
      var fieldOfViewValue = 0,
          d = 0;

      if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
        arr = this.arrayAbsteigendSortieren([x, y, z]);

        // console.log(' ');
        // console.log('viewpointDefault');
        // console.log(arr);

        var val = Number(arr[0]);

        // console.log('val: '+val);

        fieldOfViewValue = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));

        // console.log('fieldOfViewValue: '+fieldOfViewValue);

        if (x3d.get('ergebnisGeladen') === true) {
          fieldOfViewValue = fieldOfViewValue * 0.6;
        }
      } else {
        // if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        //   arr = this.arrayAbsteigendSortieren([y, z]);
        // } else if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
        //   arr = this.arrayAbsteigendSortieren([x, y]);
        // } else if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        //   arr = this.arrayAbsteigendSortieren([x, z]);
        // }

        // console.log(' ');
        // console.log('!== viewpointDefault');

        arr = this.arrayAbsteigendSortieren([x, y, z]);

        fieldOfViewValue = arr[0];
      }

      d = this.arrayAbsteigendSortieren([x, y, z])[0];

      // console.log(this.arrayAbsteigendSortieren([x, y, z])[0]);

      // console.log('d: '+d);

      x3d.set('aktuelleDistance', d);

      // console.log('WIDTH: '+document.getElementById('x3d').runtime.getWidth());
      // console.log('HEIGTH: '+document.getElementById('x3d').runtime.getHeight());

      var canvasWidth = Number(document.getElementById('x3d').runtime.getWidth());
      var canvasHeigth = Number(document.getElementById('x3d').runtime.getHeight());

      var quot = canvasWidth / canvasHeigth;

      if (x / quot < y) {
        quot = 1;
      }

      bauteile.findBy('id', 'bBox').get('boxsizes').objectAt(0).set('x', Number(fieldOfViewValue / quot) * 10);
      bauteile.findBy('id', 'bBox').get('boxsizes').objectAt(0).set('y', Number(fieldOfViewValue / quot) * 10);
      bauteile.findBy('id', 'bBox').get('boxsizes').objectAt(0).set('z', Number(fieldOfViewValue / quot) * 10);
      // x3d.set('transformHelper', !x3d.get('transformHelper'));

      resetViewpoint(fieldOfViewValue / 2 / quot, d);
    },

    x3dTranslation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen') !== true) {

        var arr = [Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 2, Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')), Math.sin(Number(x3d.get('vWinkel')) * Math.PI / 180) * Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x'))];

        arr.sort(function (a, b) {
          return b - a;
        });

        y = Number(arr[0] / 20);
      } else {
        // console.log('ergebnisGeladen else: '+x3d.get('ergebnisGeladen'));

        var dimensionen = x3d.get('dimensionen');
        x = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('x')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('x'))) / 20;
        y = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('y')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('y'))) / 20;
        z = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('z')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('z'))) / 20;

        // if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
        //   y = y * 1.5;
        // }
        //
      }

      var translation = -x + ' ' + -y + ' ' + -z;

      // console.log('X3D Translations: '+translation);

      return translation;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    verstaerkungWerner: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.verstaerkungWerner', 'model.x3ddefault.firstObject.sFolgeHolzHolz'),

    tester: (function () {
      var self = this;
      var style = "test";
      console.log('itw: ' + self.get('itw'));
      if (self.get('itw') === true) {
        style = "background-color: #5F6464; color: #FFFFFF";
      }
      return style;
    }).property(''),

    ueberstandDiagonaleVertikalEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (this.get('swg') === true && x3d.get('ergebnisGeladen') !== true && Number(x3d.get('ueberstandDiagonaleVertikal')) !== 0 && x3d.get('ueberstandDiagonaleVertikalEingetragen') === true && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.ueberstandDiagonaleVertikal', 'model.x3ddefault.firstObject.ueberstandDiagonaleVertikalEingetragen', 'model.x3ddefault.firstObject.lastViewpoint'),

    exzentrizitaetDiagonaleEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (Number(x3d.get('exzentrizitaetDiagonale')) !== 0 && x3d.get('ausmittigerstabanschluss') === true && x3d.get('exzentrizitaetDiagonaleEingetragen') === true && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.exzentrizitaetDiagonale', 'model.x3ddefault.firstObject.ausmittigerstabanschluss', 'model.x3ddefault.firstObject.exzentrizitaetDiagonaleEingetragen', 'model.x3ddefault.firstObject.lastViewpoint'),

    setMinMaxWerte: function setMinMaxWerte(bezeichner, array) {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var dimensionen = x3d.get('dimensionen');

      array.sort(function (a, b) {
        return a - b;
      });

      dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').set(bezeichner, Number(array[0]));
      dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').set(bezeichner, Number(array[array.length - 1]));
    },

    arrayAbsteigendSortieren: function arrayAbsteigendSortieren(arr) {
      var ergebnis = arr.sort(function (a, b) {
        return b - a;
      });

      return ergebnis;
    },

    getAbstandPunkte: function getAbstandPunkte(x1, x2) {
      return Math.abs(x1 - x2);
    },

    getSumme: function getSumme(x1, x2) {
      return Math.abs(x1 + x2);
    },

    eingabenUngueltig: (function () {
      var self = this;
      var value = true;

      if (self.get('projektdatenInvalid') === false && self.get('geometrieInvalid') === false && self.get('bauteileInvalid') === false && self.get('lasteinwirkungInvalid') === false && self.get('anschlussgeometrieInvalid') === false && (self.get('zwverDisabled') === true || self.get('zwischenschichtVerstaerkungInvalid') === false)) {
        value = false;
      }

      return value;
    }).property('projektdatenInvalid', 'geometrieInvalid', 'bauteileInvalid', 'lasteinwirkungInvalid', 'zwischenschichtVerstaerkungInvalid', 'anschlussgeometrieInvalid', 'zwverDisabled'),

    reiterAnschlussgeometrieAnzeigen: (function () {

      var self = this;
      var value = false;

      // if(self.get('swg') === true){
      if (self.get('wuerth') === true || self.get('swg') === true || self.get('verbaende') === true || self.get('pollmeier') === true) {
        var bau = self.controllerFor('bauteile');
        bau.set('reiterAnschlussgeometrieAnzeigen', true);

        value = true;
      }

      return value;
    }).property('wuerth', 'swg', 'itw'),

    inDevelopment: (function () {

      if (this.get('environment').search('dev') !== -1) {
        return true;
      } else {
        return false;
      }
    }).property(''),

    bauteilwinkel: (function () {
      return this.get('itw') === true ? "vWinkel" : "phi";
    }).property(''),

    uscheiben: (function () {
      var self = this;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = false;

      if (x3d.get('ergebnisGeladen')) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "uScheibe") {
            value = true;
            return true;
          }
        });
      }

      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.schraubenlisteAktualisiert'),

    uScheibenListe: (function () {
      var self = this;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var liste = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen')) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "uScheibe") {
            liste.add(item);
          }
        });
      }

      return liste.toArray();
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.schraubenlisteAktualisiert'),

    muttern: (function () {
      var self = this;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = false;

      if (x3d.get('ergebnisGeladen')) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "mutter") {
            value = true;
            return true;
          }
        });
      }

      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.schraubenlisteAktualisiert'),

    mutternListe: (function () {
      var self = this;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var liste = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen')) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "mutter") {
            liste.add(item);
          }
        });
      }

      return liste.toArray();
    }).property('model.x3ddefault.firstObject.ergebnisGeladen', 'model.x3ddefault.firstObject.schraubenlisteAktualisiert'),

    fileExtension: (function () {
      var self = this;

      if (self.get('wuerth')) return ".wu03";else if (self.get('swg')) return ".sw03";else if (self.get('swghandel')) return ".sw03";else if (self.get('bauder')) return ".ba03";else if (self.get('reisser')) return ".re03";else if (self.get('bti')) return ".bt03";else return 'xxx';
    }).property('wuerth', 'swg', 'swghandel', 'bauder', 'reisser')

  });

});